<template>
  <div>
    <RightWindow :title="title" @closedWindow="closedWindow">
        <div class="center_b_2">
          <ul class="ultab">
            <li class='hover'><a href="javascript:void(0)">详细信息</a></li>
          </ul>
        </div>
        <div class="center_b_3">
          <ul>

            <li class="up"><span>消费时间：</span>{{getDateTime(MmsData.data.xiaofeishijian)}}</li>
            <li class="up"><span>消费账号：</span>{{MmsData.data.userPhone}}</li>
            <li class="up"><span>消费账号姓名：</span>{{MmsData.data.userXM}}</li>
            <li class="up"><span>消费门店：</span>{{MmsData.data.fenpeimendianName}}</li>
            <li class="up"><span>消费项目：</span>{{MmsData.data.items}}</li>
            <div class="minTable">
              <h3>消费项目明细</h3>
              <table>
                <colgroup>
                  <col width="35">
                  <col >
                  <col >
                  <col width="10%">
                  <col width="10%">
                  <col width="10%">
                  <col width="50">
                  <col width="10%">
                  <col width="10%">
                </colgroup>
                <thead>
                  <tr>
                    <th>类型</th>
                    <th>名称</th>
                    <th>服务人员</th>
                    <th>原价</th>
                    <th>实价</th>
                    <th>数量</th>
                    <th>次卡数</th>
                    <th>应收</th>
                    <th>实收</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in items" :key="item.id">
                    <td>{{item.itemleixing==2?"项目":"商品"}}</td>
                    <td>{{item.itemName}}</td>
                    <td>{{item.fwryNames}}</td>
                    <td>{{item.defjiage}}</td>
                    <td>{{item.yingshou/item.num}}</td>
                    <td>{{item.num}}</td>
                    <td>{{item.xiaofeicishu}}</td>
                    <td>{{item.yingshou}}</td>
                    <td>{{item.shishou}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <li class="up"><span>消费金额：</span>{{MmsData.data.zongjijine}}</li>
            <li class="up"><span>优惠金额：</span>{{MmsData.data.huiyuanyouhui}}</li>
            <li class="up"><span>应付金额：</span>{{MmsData.data.yingfukuan}}</li>
            <li class="up"><span>实付金额：</span>{{MmsData.data.shifukuan}}</li>
            <li class="up"><span>支付方式：</span>{{getZFFS(MmsData.data.zffs)}}</li>
            <template v-if="MmsData.data.chae">
            <li class="up"><span>余额不足差额：</span>{{MmsData.data.chae}}</li>
            <li class="up"><span>差额补优惠：</span>{{MmsData.data.kouhuiyuanyouhui}}</li>
            <li class="up"><span>差额应付：</span>{{MmsData.data.yingfujine}}</li>
            </template>
            <template v-if="MmsData.data.shifujine">
            <li class="up"><span>实付2：</span>{{MmsData.data.shifujine}}</li>
            <li class="up"><span>支付方式2：</span>{{getZFFS(MmsData.data.zffs2)}}</li>
            </template>
            <template v-if="MmsData.data.userPhone">
            <li class="up"><span>消费后余额：</span>{{MmsData.data.nowyue}}</li>
            <li class="up"><span>消费后赠送金额：</span>{{MmsData.data.nowzsyue}}</li>
            <li class="abs"><span>消费次数：</span><p v-for="item in CishuList" :key="item.id">{{item.xiangmuName}} {{item.num}}次</p></li>
            </template>
            <li class="up"><span>状态：</span>{{returnLB(MmsData.data.state)}}</li>
            <li class="up"><span>消费备注：</span>{{MmsData.data.fuwuinfo}}</li>
            <template v-if="MmsData.data.resetId">
              <li class="up"><span>撤销时间：</span>{{getDateTime(MmsData.data.resetDate)}}</li>
              <li class="up"><span>撤销人：</span>{{MmsData.data.resetName}}</li>
            </template>
          </ul>
          <div class="center_b_4">
            <button class="deltab" v-if="MmsData.data.state==1" @click="resetJL(MmsData.data.id)" v-pow:3501>撤销</button>
            <button class="remole" @click="closedWindow">关闭</button>
          </div>
        </div>
    </RightWindow>
  </div>

</template>
<script>
import axios from '@/http/axios'
import moment from 'moment'
import {zffsList} from '@/data/data'
export default {
  name: 'RRPSinfo',
  props:{
    MmsData:Object,
    title:String,
  },
  data() {
    return {
      CishuList:[],
      items:[],
    }
  },
  watch:{
    "MmsData.data.id":{
      handler(n){
        this.getxfCishu(n)
      }
    }
  },
  created() {
    this.getxfCishu(this.MmsData.data.id)
  },
  components: {},
  mounted() {},
  methods: {
    getZFFS(num){
      let zffs=zffsList.find(e=>e.id==num)
      if(zffs) return zffs.name
      else{
        if(num==1) return "会员扣款"
        else return '无'
      }
    },
    resetJL(id){
      this.$trueOrFalse({
        title: '重要提示',
        content: `您确定撤销编号为${id}的这条记录？（如果消费的赠送金额，撤销后会返还到余额！）`,
        torf: true,
        suc:()=>{
          axios.post('/record/resetXFById',{
            id:id,
          }).then((res) => {
            if(res.code==5000){
              this.$Mesg({content:"撤销成功！"})
              this.$parent.getData()
              this.closedWindow()
            }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
          })
        }          
      })
    },
    getxfCishu(id){
      axios.post('/record/getXFInfoById',{
        id:id,
      }).then((res) => {
        if(res.code==5000){
          this.CishuList=res.data.ck
          this.items=res.data.item
        }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
      })
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    getDateTime(date){
      return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },
    closedWindow(){
      this.$emit('closedWindow')
    },
    
  },
}
</script>
