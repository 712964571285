<template>
<div class="list">
  <div class="list_b">
    <ul>
      <li v-if="$store.state.User.jibieId==0">
        <p>门店：</p>
        <select @change="getData(1)" v-model="form.fenpeimendianId">
          <option value="">全部</option>
          <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
        </select>
      </li>
      <li>
        <p>消费时间：</p>
        <input type="date" @change="getData(1)" v-model="form.start"><span> &nbsp;-&nbsp; </span>
        <input type="date" @change="getData(1)" v-model="form.end">
      </li>
      <li>
        <p>快捷查询：</p>
        <select v-model="Sdate" @change="addDate">
          <option value="0">全部</option>
          <option value="D">今日</option>
          <option value="W">本周</option>
          <option value="M">本月</option>
          <option value="NM">上月</option>
        </select>
      </li>
      <li>
        <p>编号：</p>
        <input type="text" v-model="form.id" @keypress.enter="getData(1)">
      </li>
      <li>
        <button type="button" @click="getData(1)">查询</button>
      </li>
      <div class="clear"></div>
    </ul>
  </div>
  <div class="home_list">
    <div class="drags_out">
      <table class="drags_3">
        <colgroup>
          <col>
          <col width="90">
        </colgroup>
        <thead>
          <tr>
            <th style="text-align:center">编号</th>
            <th style="text-align:center">消费时间</th>
            <th>消费账号</th>
            <th>消费门店</th>
            <th>消费项目</th>
            <th style="text-align:center">总金额 | 会员优惠</th>
            <th style="text-align:center">应付款</th>
            <th style="text-align:center">实付款</th>
            <th style="text-align:center">余额|赠送余额</th>
            <th>操作人</th>
            <th style="text-align:center">状态</th>
            <th>操作</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in recordList" :key="item.id">
            <td style="text-align:center">{{item.id}}</td>
            <td style="text-align:center">{{getDateTime(item.xiaofeishijian)}}</td>
            <td>
              <p :class="powShow(3600)?'blue':''" @click="goVIPList(item.userPhone)">{{item.userXM}}</p>
              <p>{{item.userPhone}}</p>
            </td>
            <td>{{item.fenpeimendianName}}</td>
            <td>{{item.items}}</td>
            <td style="text-align:center">
              <p>{{item.zongjijine}}</p>
              <p>-{{item.huiyuanyouhui}}</p>
            </td>
            <td style="text-align:center">
              <p>{{item.yingfukuan}}</p>
            </td>
            <td style="text-align:center">
              <p>{{item.shifukuan}} | {{getZFFS(item.zffs)}}</p>
              <p v-if="item.shifujine">{{item.shifujine}} | {{getZFFS(item.zffs2)}}</p>
            </td>
            <td style="text-align:center">
              <p>{{item.nowyue}}</p>
              <p>{{item.nowzsyue}}</p>
            </td>
            
            <td>{{item.czrName}}</td>
            <td style="text-align:center"><p><span :class="returnLBClass(item.state)">{{returnLB(item.state)}}</span></p></td>
            <td>
              <div class="buttonGroup">
                <button type="button" class="suc" @click="showInfo(item)">详情</button>
                <button type="button" class="err" v-if="item.state==1" @click="resetJL(item.id)" v-pow:3401>撤销</button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="list_c">
    <Page :pageData="pageData" @resetData="resetData"></Page>
    <div class="clear"></div>
  </div>
  <CRPSinfo :title="title" v-if="infoShow" :MmsData="MmsData" @closedWindow="closedWindow"></CRPSinfo>
</div>
</template>

<script>
import CRPSinfo from './CRPSinfo.vue'
import axios from '@/http/axios'
import {zffsList} from '@/data/data'
import moment from 'moment'
import { mapMutations } from "vuex"
export default {
  name: 'consumptionRecords',
  props: {},
  data () {
    return {
      zffsList:zffsList,
      form:{
        id:null,
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        start:'',
        end:'',
      },
      title:'消费记录详情',
      MmsData:{
        new:false,
      },
      infoShow:false,
      Sdate:0,
      storeList:[],
      recordList:[],
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  computed:{
    powShow(){
      return (pow)=>{
        if(this.$store.state.User){
          if(this.$store.state.User.jibieId==0||(this.$store.state.User.pow&&this.$store.state.User.pow.indexOf(pow)>=0)){
            return true
          }else return false
        }else return false
      }
    },
  },
  created () {
    let id=this.$route.query.id
    if(id) this.form.id=parseInt(id) 
    this.init()
  },
  mounted () {},
  components: {CRPSinfo},
  methods: {
     ...mapMutations(['addNav']),
    goVIPList(phone){
      if(this.powShow(3600)&&phone){
        this.addNav({
          name:'会员列表',
          path:'/VIPList'
        })
        this.$router.push({path:"/VIPList",query:{phone}})
      }
    },
    resetJL(id){
      this.$trueOrFalse({
        title: '重要提示',
        content: `您确定撤销编号为${id}的这条记录？（如果消费的赠送金额，撤销后会返还到余额！）`,
        torf: true,
        suc:()=>{
          axios.post('/record/resetXFById',{
            id:id,
          }).then((res) => {
            if(res.code==5000){
              this.$Mesg({content:"撤销成功！"})
              this.getData()
            }else this.$Mesg({content: res.msg,errOrSuc: 'err'})
          })
        }          
      })
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    showInfo(item){
      this.MmsData.data=item
      this.infoShow=true
    },
    addDate(){
      let date =this.Sdate
      let s= moment(), e= moment()
      if(date==0){
        s=''
        e=''
      }
      else if(date=="NM"){
        s=s.subtract(1,"M").startOf('M').format("YYYY-MM-DD")
        e=e.subtract(1,"M").endOf('M').format("YYYY-MM-DD")
      }else{
         s= s.startOf(date).format("YYYY-MM-DD")
         e= e.endOf(date).format("YYYY-MM-DD")
      }
      this.form.start=s
      this.form.end=e
      this.getData()
    },
    getZFFS(num){
      let zffs=this.zffsList.find(e=>e.id==num)
      if(zffs) return zffs.name
      else{
        if(num==1) return "会员扣款"
        else return '无'
      }
    },
    returnLBClass(lb){
      if(lb==1){
        return 'blue'
      }
      else{
        return 'red'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    getDateTime(sr){
      if(sr) return moment(sr).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    getData(index){
      if(index) this.pageData.pageIndex=1
      axios.post('/record/xiaofeiJL',{...this.form,...this.pageData}).then((res) => {
        if(res.code===5000){
          this.recordList=res.data
          this.pageData.total=res.total
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    closedWindow(){
      this.infoShow=false
    },
    init(){
      if(this.$store.state.User.jibieId==0) this.getStore()
      this.getData()
    },
  }
}
</script>
